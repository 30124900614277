import { ChangeDemandStateType } from "../models/changeDemads";

export const pascalCaseWithSpaces = (input: string) =>
  input
    .replace(/(_|-)/g, " ")
    .trim()
    .replace(/\w\S*/g, function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1);
    })
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

export const keepFileExtensions = (originalName: string, newName: string) => {
  const lastIndex = originalName.lastIndexOf(".");
  if (lastIndex !== -1) {
    const originalExt = originalName.substring(lastIndex);

    if (!newName.toLowerCase().endsWith(originalExt.toLowerCase())) {
      newName = newName + originalExt;
    }
  }
  return newName;
};

export const getTextForState = (
  t: (key: string) => string,
  state: ChangeDemandStateType
): string => {
  switch (state) {
    case ChangeDemandStateType.New:
      return t("changeDemand.stateFilter.new");
    case ChangeDemandStateType.Accepted:
      return t("changeDemand.stateFilter.approved");
    case ChangeDemandStateType.Decline:
      return t("changeDemand.stateFilter.declined");
    default:
      return "";
  }
};
