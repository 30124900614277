import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import {
  selectChangeDemand,
  selectChangeDemandState,
  getChangeDemand,
} from "../../store/changeDemand";

import { StoreState } from "../../store/storeState";
import {
  ChangeDemandStateType,
  IChangeDemandDetail,
} from "../../models/changeDemads";
import { useTranslation } from "react-i18next";
import Loader from "../common/Loader";
import { Formik } from "formik";
import { FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import FormGroupRow from "../common/form/FormGroupRow";
import { ClientIdentityType, IClient } from "../../models/client";
import { FormButton } from "../../styles/button";
import changeDemandsApi from "../../api/changeDemands";
import { promiseToast } from "../../utils/toasts";
import { selectClient, getClient } from "../../store/client";
import PromiseModalWithText from "../common/PromiseModalWithText";
import { getTextForState } from "../../utils/string";

// Import any other necessary components or styles

interface ChangeDemandProps {
  changeDemandState: StoreState;
  changeDemand: IChangeDemandDetail | null;
  getChangeDemand: (id: number) => void;
  client: IClient | null;
  getClient: (id: number) => void;
}

const ChangeDemand: FC<ChangeDemandProps> = ({
  changeDemandState,
  changeDemand,
  getChangeDemand,
  client,
  getClient,
}) => {
  const { changeDemandId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalData, setModalData] = useState<{
    title: string;
    resolve: (value: string) => void;
    reject: (reason?: any) => void;
  } | null>(null);

  useEffect(() => {
    if (changeDemand?.clientDemandId) getClient(changeDemand?.clientId);
  }, [changeDemand, getClient]);

  useEffect(() => {
    if (changeDemandId) {
      getChangeDemand(Number(changeDemandId));
    }
  }, [changeDemandId, getChangeDemand]);

  if (changeDemandState === StoreState.Loading) {
    return <Loader />; // Adjust as needed for your loading state
  }

  if (!changeDemand) {
    return <div> {t("changeDemandNotFound")} </div>; // Adjust as needed for null state
  }

  if (!client) {
    return <div> {t("clientNotFound")} </div>;
  }

  const createdAt = new Date(changeDemand.createdAt);

  const handleApproved = async () => {
    await promiseToast(
      async () => {
        await changeDemandsApi.approvedChangeDemand(changeDemand.id, "");
      },
      t("pending.save"),
      t("success.clientInfoChanged"),
      t("errors.unknown")
    );

    navigate(-1);
  };

  const handleReject = async () => {
    const notePromise = new Promise(
      (resolve: (value: string) => void, reject) => {
        setModalData({
          title: t("changeDemand.addNote"),
          resolve,
          reject,
        });
      }
    );

    await promiseToast(
      async () => {
        try {
          const note = await notePromise;

          if (!note) throw new Error("Note is empty");

          setModalData(null);
          await changeDemandsApi.rejectChangeDemand(changeDemand.id, note);
        } catch (e) {
          setModalData(null);
          throw e;
        }
      },
      t("pending.save"),
      t("success.clientChangeDemandRejection"),
      t("common.cancel")
    );

    navigate(-1);
  };

  const handleDownloadFile = async (fileId: number, fileName: string) => {
    const response = await changeDemandsApi.getUrlForDownloadDocument(
      changeDemand.id,
      fileId
    );

    const link = document.createElement("a");
    link.href = response.data;
    link.download = fileName;
    link.target = "_blank";
    link.click();
  };

  const isIdentityType = !!client.identityType || !!changeDemand.identityType;
  const isNewState =
    changeDemand.clientDemandState === ChangeDemandStateType.New;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          margin: "1rem 0",
        }}
      >
        {t("changeDemand.title")}: {changeDemand.firstName}{" "}
        {changeDemand.lastName} ({t("client.id")}: {changeDemand.id})
      </h1>

      <div>
        <h2>
          {t("changeDemand.createdAt")}:{" "}
          {createdAt.toLocaleDateString().replaceAll("/", ".") +
            " " +
            createdAt.toLocaleTimeString()}
        </h2>
        <h2>
          {t("assistance.state")}:{" "}
          {getTextForState(t, changeDemand.clientDemandState)}
        </h2>
      </div>

      <Formik<IClient> initialValues={client} onSubmit={() => {}}>
        {({ values }) => (
          <>
            <FormGroup>
              <Input
                label={t("client.title")}
                name="title"
                disabled
                isAfter={
                  Boolean(changeDemand.title) &&
                  changeDemand.title !== values.title
                }
                afterText={changeDemand.title}
                afterTextTooltip={
                  changeDemand.title && t("changeDemand.newValueTooltip")
                }
              />
              <Input
                label={t("client.firstName")}
                name="firstName"
                disabled
                isAfter={
                  Boolean(changeDemand.firstName) &&
                  changeDemand.firstName !== values.firstName
                }
                afterText={changeDemand.firstName}
                afterTextTooltip={
                  changeDemand.firstName && t("changeDemand.newValueTooltip")
                }
              />
              <Input
                label={t("client.lastName")}
                name="lastName"
                disabled
                isAfter={
                  Boolean(changeDemand.lastName) &&
                  changeDemand.lastName !== values.lastName
                }
                afterText={changeDemand.lastName}
                afterTextTooltip={
                  changeDemand.lastName && t("changeDemand.newValueTooltip")
                }
              />
              <Input
                label={t("client.maidenName")}
                name="maidenName"
                disabled
                isAfter={
                  Boolean(changeDemand.maidenName) &&
                  changeDemand.maidenName !== values.maidenName
                }
                afterText={changeDemand.maidenName}
                afterTextTooltip={
                  changeDemand.maidenName && t("changeDemand.newValueTooltip")
                }
              />
              <Input
                label={t("client.degree")}
                name="degree"
                disabled
                isAfter={
                  Boolean(changeDemand.degree) &&
                  changeDemand.degree !== values.degree
                }
                afterText={changeDemand.degree}
                afterTextTooltip={
                  changeDemand.degree && t("changeDemand.newValueTooltip")
                }
              />
            </FormGroup>
            <hr />
            <FormGroup>
              <h2>{t("client.addressPermanent")}</h2>
              <FormGroupRow
                gridTemplateColumns="2fr 1fr 1fr"
                names={[
                  "addressPermanentCountry",
                  "addressPermanentCity",
                  "addressPermanentPostalCode",
                ]}
                label={t("client.streetAndHouseNumberAndLandRegistryNumber")}
              >
                <Input
                  name="addressPermanentStreet"
                  disabled
                  isAfter={
                    Boolean(changeDemand.permanentAddress?.street) &&
                    changeDemand.permanentAddress?.street !==
                      values.addressPermanentStreet
                  }
                  afterText={changeDemand.permanentAddress?.street}
                  afterTextTooltip={
                    changeDemand.permanentAddress?.street &&
                    t("changeDemand.newValueTooltip")
                  }
                />
                <Input
                  name="addressPermanentHouseNumber"
                  disabled
                  isAfter={
                    Boolean(changeDemand.permanentAddress?.houseNumber) &&
                    changeDemand.permanentAddress?.houseNumber !==
                      values.addressPermanentHouseNumber
                  }
                  afterText={changeDemand.permanentAddress?.houseNumber}
                  afterTextTooltip={
                    changeDemand.permanentAddress?.houseNumber &&
                    t("changeDemand.newValueTooltip")
                  }
                />
                <Input
                  name="addressPermanentLandRegistryNumber"
                  disabled
                  isAfter={
                    Boolean(
                      changeDemand.permanentAddress?.landRegistryNumber
                    ) &&
                    changeDemand.permanentAddress?.landRegistryNumber !==
                      values.addressPermanentLandRegistryNumber
                  }
                  afterText={changeDemand.permanentAddress?.landRegistryNumber}
                  afterTextTooltip={
                    changeDemand.permanentAddress?.landRegistryNumber &&
                    t("changeDemand.newValueTooltip")
                  }
                />
              </FormGroupRow>
              <FormGroupRow
                gridTemplateColumns="3fr 1fr"
                names={["addressActualCity", "addressActualPostCode"]}
                label={t("client.cityAndPostCode")}
              >
                <Input
                  name="addressPermanentCity"
                  disabled
                  isAfter={
                    Boolean(changeDemand.permanentAddress?.city) &&
                    changeDemand.permanentAddress?.city !==
                      values.addressPermanentCity
                  }
                  afterText={changeDemand.permanentAddress?.city}
                  afterTextTooltip={
                    changeDemand.permanentAddress?.city &&
                    t("changeDemand.newValueTooltip")
                  }
                />
                <Input
                  name="addressPermanentPostCode"
                  disabled
                  isAfter={
                    Boolean(changeDemand.permanentAddress?.postCode) &&
                    changeDemand.permanentAddress?.postCode !==
                      values.addressPermanentPostCode
                  }
                  afterText={changeDemand.permanentAddress?.postCode}
                  afterTextTooltip={
                    changeDemand.permanentAddress?.postCode &&
                    t("changeDemand.newValueTooltip")
                  }
                />
              </FormGroupRow>
              <Input
                disabled
                key="addressActualIsSame"
                name="addressActualIsSame"
                type="checkbox"
                label={t(`client.addressActualIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
                isAfter={
                  changeDemand.addressActualIsSame || client.addressActualIsSame
                }
                afterText={
                  changeDemand.addressActualIsSame
                    ? t("common.yes")
                    : t("common.no")
                }
                afterTextTooltip={
                  changeDemand.addressActualIsSame !==
                  client.addressActualIsSame
                    ? t("changeDemand.newValueTooltip")
                    : null
                }
              />
              <Input
                disabled
                key="addressMailIsSame"
                name="addressMailIsSame"
                type="checkbox"
                label={t(`client.addressMailIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
                isAfter={
                  changeDemand.addressMailIsSame || client.addressMailIsSame
                }
                afterText={
                  changeDemand.addressMailIsSame
                    ? t("common.yes")
                    : t("common.no")
                }
                afterTextTooltip={
                  changeDemand.addressMailIsSame !== client.addressMailIsSame
                    ? t("changeDemand.newValueTooltip")
                    : null
                }
              />
            </FormGroup>

            <hr />

            {!changeDemand.addressActualIsSame && (
              <FormGroup>
                <h2> {t("client.addressActual")} </h2>
                <FormGroupRow
                  gridTemplateColumns="2fr 1fr 1fr"
                  names={[
                    "addressActualCountry",
                    "addressActualCity",
                    "addressActualPostalCode",
                  ]}
                  label={t("client.streetAndHouseNumberAndLandRegistryNumber")}
                >
                  <Input
                    name="addressActualStreet"
                    disabled
                    isAfter={
                      Boolean(changeDemand.actualAddress?.street) &&
                      changeDemand.actualAddress?.street !==
                        values.addressActualStreet
                    }
                    afterText={changeDemand.actualAddress?.street}
                    afterTextTooltip={
                      changeDemand.actualAddress?.street &&
                      t("changeDemand.newValueTooltip")
                    }
                  />
                  <Input
                    name="addressActualHouseNumber"
                    disabled
                    isAfter={
                      Boolean(changeDemand.actualAddress?.houseNumber) &&
                      changeDemand.actualAddress?.houseNumber !==
                        values.addressActualHouseNumber
                    }
                    afterText={changeDemand.actualAddress?.houseNumber}
                    afterTextTooltip={
                      changeDemand.actualAddress?.houseNumber &&
                      t("changeDemand.newValueTooltip")
                    }
                  />
                  <Input
                    name="addressActualLandRegistryNumber"
                    disabled
                    isAfter={
                      Boolean(changeDemand.actualAddress?.landRegistryNumber) &&
                      changeDemand.actualAddress?.landRegistryNumber !==
                        values.addressActualLandRegistryNumber
                    }
                    afterText={changeDemand.actualAddress?.landRegistryNumber}
                    afterTextTooltip={
                      changeDemand.actualAddress?.landRegistryNumber &&
                      t("changeDemand.newValueTooltip")
                    }
                  />
                </FormGroupRow>
                <FormGroupRow
                  gridTemplateColumns="3fr 1fr"
                  names={["addressActualCity", "addressActualPostCode"]}
                  label={t("client.cityAndPostCode")}
                >
                  <Input
                    name="addressActualCity"
                    disabled
                    isAfter={
                      Boolean(changeDemand.actualAddress?.city) &&
                      changeDemand.actualAddress?.city !==
                        values.addressActualCity
                    }
                    afterText={changeDemand.actualAddress?.city}
                    afterTextTooltip={
                      changeDemand.actualAddress?.city &&
                      t("changeDemand.newValueTooltip")
                    }
                  />
                  <Input
                    name="addressActualPostCode"
                    disabled
                    isAfter={
                      Boolean(changeDemand.actualAddress?.postCode) &&
                      changeDemand.actualAddress?.postCode !==
                        values.addressActualPostCode
                    }
                    afterText={changeDemand.actualAddress?.postCode}
                    afterTextTooltip={
                      changeDemand.actualAddress?.postCode &&
                      t("changeDemand.newValueTooltip")
                    }
                  />
                </FormGroupRow>
              </FormGroup>
            )}

            <hr />

            {!changeDemand.addressMailIsSame && (
              <FormGroup>
                <h2> {t("client.addressMail")} </h2>
                <FormGroupRow
                  gridTemplateColumns="2fr 1fr 1fr"
                  names={[
                    "addressMailCountry",
                    "addressMailCity",
                    "addressMailPostalCode",
                  ]}
                  label={t("client.streetAndHouseNumberAndLandRegistryNumber")}
                >
                  <Input
                    name="addressMailStreet"
                    disabled
                    isAfter={
                      Boolean(changeDemand.mailAddress?.street) &&
                      changeDemand.mailAddress?.street !==
                        values.addressMailStreet
                    }
                    afterText={changeDemand.mailAddress?.street}
                    afterTextTooltip={
                      changeDemand.mailAddress?.street &&
                      t("changeDemand.newValueTooltip")
                    }
                  />
                  <Input
                    name="addressMailHouseNumber"
                    disabled
                    isAfter={
                      Boolean(changeDemand.mailAddress?.houseNumber) &&
                      changeDemand.mailAddress?.houseNumber !==
                        values.addressMailHouseNumber
                    }
                    afterText={changeDemand.mailAddress?.houseNumber}
                    afterTextTooltip={
                      changeDemand.mailAddress?.houseNumber &&
                      t("changeDemand.newValueTooltip")
                    }
                  />
                  <Input
                    name="addressMailLandRegistryNumber"
                    disabled
                    isAfter={
                      Boolean(changeDemand.mailAddress?.landRegistryNumber) &&
                      changeDemand.mailAddress?.landRegistryNumber !==
                        values.addressMailLandRegistryNumber
                    }
                    afterText={changeDemand.mailAddress?.landRegistryNumber}
                    afterTextTooltip={
                      changeDemand.mailAddress?.landRegistryNumber &&
                      t("changeDemand.newValueTooltip")
                    }
                  />
                </FormGroupRow>
                <FormGroupRow
                  gridTemplateColumns="3fr 1fr"
                  names={["addressMailCity", "addressMailPostCode"]}
                  label={t("client.cityAndPostCode")}
                >
                  <Input
                    name="addressMailCity"
                    disabled
                    isAfter={
                      Boolean(changeDemand.mailAddress?.city) &&
                      changeDemand.mailAddress?.city !== values.addressMailCity
                    }
                    afterText={changeDemand.mailAddress?.city}
                    afterTextTooltip={
                      changeDemand.mailAddress?.city &&
                      t("changeDemand.newValueTooltip")
                    }
                  />
                  <Input
                    name="addressMailPostCode"
                    disabled
                    isAfter={
                      Boolean(changeDemand.mailAddress?.postCode) &&
                      changeDemand.mailAddress?.postCode !==
                        values.addressMailPostCode
                    }
                    afterText={changeDemand.mailAddress?.postCode}
                    afterTextTooltip={
                      changeDemand.mailAddress?.postCode &&
                      t("changeDemand.newValueTooltip")
                    }
                  />
                </FormGroupRow>
              </FormGroup>
            )}

            <hr />

            <FormGroup>
              <h2> {t("client.contact")} </h2>

              <Input
                name="mobilePhone"
                disabled
                label={t("client.mobilePhone")}
                isAfter={
                  Boolean(changeDemand.mobilePhone) &&
                  changeDemand.mobilePhone !== values.mobilePhone
                }
                afterText={changeDemand.mobilePhone}
                afterTextTooltip={
                  changeDemand.mobilePhone && t("changeDemand.newValueTooltip")
                }
              />

              <Input
                name="phone"
                disabled
                label={t("client.phone")}
                isAfter={
                  Boolean(changeDemand.phone) &&
                  changeDemand.phone !== values.phone
                }
                afterText={changeDemand.phone}
                afterTextTooltip={
                  changeDemand.phone && t("changeDemand.newValueTooltip")
                }
              />

              <Input
                name="email"
                disabled
                label={t("client.email")}
                isAfter={
                  Boolean(changeDemand.email) &&
                  changeDemand.email !== values.email
                }
                afterText={changeDemand.email}
                afterTextTooltip={
                  changeDemand.email && t("changeDemand.newValueTooltip")
                }
              />

              <Input
                name="dataBox"
                disabled
                label={t("client.dataBox")}
                isAfter={
                  Boolean(changeDemand.dataBox) &&
                  changeDemand.dataBox !== values.dataBox
                }
                afterText={changeDemand.dataBox}
                afterTextTooltip={
                  changeDemand.dataBox && t("changeDemand.newValueTooltip")
                }
              />
            </FormGroup>

            <hr />

            {
              <FormGroup
                style={{
                  marginBottom: "20px",
                }}
              >
                <h2> {t("client.identification")} </h2>
                <FormGroupRow
                  gridTemplateColumns="1fr 1fr"
                  names={["identityNumber", "identityType"]}
                  label={t("client.identification")}
                >
                  <Input
                    disabled
                    key="identityType"
                    name="identityType"
                    component="select"
                    isAfter={
                      Boolean(changeDemand.identityType) &&
                      changeDemand.identityType !== values.identityType
                    }
                    afterText={changeDemand.identityType}
                    afterTextTooltip={
                      changeDemand.identityType &&
                      t("changeDemand.newValueTooltip")
                    }
                  >
                    <option value="">{t("client.identityTypes.null")}</option>
                    {(
                      Object.keys(ClientIdentityType) as Array<
                        keyof typeof ClientIdentityType
                      >
                    ).map((key) => (
                      <option key={key} value={ClientIdentityType[key]}>
                        {t("client.identityTypes." + ClientIdentityType[key])}
                      </option>
                    ))}
                  </Input>
                  {isIdentityType &&
                    values.identityType !== ClientIdentityType.Unverified && (
                      <Input
                        disabled
                        key="identityCode"
                        name="identityCode"
                        maxLength={100}
                        isAfter={
                          Boolean(changeDemand.identityCode) &&
                          changeDemand.identityCode !== values.identityCode
                        }
                        afterText={changeDemand.identityCode}
                        afterTextTooltip={
                          changeDemand.identityCode &&
                          t("changeDemand.newValueTooltip")
                        }
                      />
                    )}
                </FormGroupRow>

                <Input
                  name="healthInsurance.name"
                  disabled
                  label={t("client.healthInsurance")}
                  isAfter={
                    Boolean(changeDemand.healthInsurance) &&
                    changeDemand.healthInsurance?.id !==
                      values.healthInsurance?.id
                  }
                  afterText={changeDemand.healthInsurance?.name}
                  afterTextTooltip={
                    changeDemand.healthInsurance &&
                    t("changeDemand.newValueTooltip")
                  }
                />
              </FormGroup>
            }
            {changeDemand.clientDemandFiles?.length ? (
              <h2> {t("changeDemand.files")} </h2>
            ) : (
              <> </>
            )}
            {changeDemand.clientDemandFiles &&
              changeDemand.clientDemandFiles.map((file) => (
                <span
                  onClick={() => handleDownloadFile(file.id, file.fileName)}
                  style={{
                    padding: ".75rem",
                    backgroundColor: "#f5f5f5",
                    cursor: "pointer",
                  }}
                >
                  {file.fileName}
                </span>
              ))}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: "1rem",
                marginBottom: "2rem",
              }}
            >
              <FormButton
                onClick={() => {
                  navigate(-1);
                }}
                ver="secondary"
              >
                {t("common.back")}
              </FormButton>
              <FormButton disabled={!isNewState} onClick={handleApproved}>
                {t("changeDemand.approved")}
              </FormButton>
              <FormButton disabled={!isNewState} onClick={handleReject}>
                {t("changeDemand.reject")}
              </FormButton>
            </div>
          </>
        )}
      </Formik>
      {modalData && (
        <PromiseModalWithText
          title={t("changeDemand.addNote")}
          resolve={modalData.resolve}
          reject={modalData.reject}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  changeDemandState: selectChangeDemandState(state),
  changeDemand: selectChangeDemand(state),
  client: selectClient(state),
});

const mapDispatchToProps = {
  getChangeDemand,
  getClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDemand);
