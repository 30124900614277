import { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import { InputContainer, InputRow } from "../../../styles/form";
import { COLORS } from "../../../styles/colors";
import {
  InputField,
  InputIcon,
  InputLabel,
  InputTextAfter,
} from "./InputStyles";
import FormError from "./FormError";
import RequiredMark from "./RequiredMark";
import _ from "lodash";

const Input: FC<{
  name: string;
  label?: string;
  error?: boolean;
  type?: string;
  component?: string;
  textAfter?: string | JSX.Element;
  inputWidth?: string;
  inputHeight?: string;
  disabled?: boolean;
  tooltipLeft?: boolean;
  maxLength?: number;
  autoComplete?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isAfter?: boolean | null;
  afterText?: string | null;
  afterTextTooltip?: string | null;
}> = ({
  name,
  label,
  error,
  type,
  component,
  textAfter,
  inputWidth,
  inputHeight,
  disabled,
  tooltipLeft,
  maxLength,
  autoComplete = "off",
  children,
  onChange,
  isAfter,
  afterText,
  afterTextTooltip,
}) => {
  const [inputType, setInputType] = useState(type);

  const { handleChange, errors } = useFormikContext<any>();

  return (
    <InputRow
      theme={{
        isAfter,
        afterText,
        afterTextTooltip,
      }}
    >
      {label && (
        <InputLabel htmlFor={name}>
          {label}
          {_.get(errors, name) && <RequiredMark />}
        </InputLabel>
      )}
      <InputContainer>
        <InputField
          type={inputType || "text"}
          component={component}
          id={name}
          name={name}
          $error={error}
          width={inputWidth}
          height={inputHeight}
          disabled={disabled}
          pattern={inputType === "number" ? "[0-9]*" : undefined}
          inputMode={inputType === "number" ? "numeric" : undefined}
          maxLength={maxLength}
          autoComplete={autoComplete}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(event);
            handleChange(event);
          }}
          onWheel={(event: React.WheelEvent<HTMLInputElement>) => {
            if (inputType === "number") {
              event.currentTarget.blur();
            }
          }}
        >
          {children}
        </InputField>
        {type === "password" && (
          <InputIcon
            $error={error}
            hasHover
            onClick={() =>
              inputType === "password"
                ? setInputType("text")
                : setInputType("password")
            }
          >
            <FontAwesomeIcon
              icon={inputType === "password" ? faEye : faEyeSlash}
              color={COLORS.formIconColor}
            />
          </InputIcon>
        )}
        {textAfter && <InputTextAfter>{textAfter}</InputTextAfter>}
        {error && <FormError name={name} tooltipLeft={tooltipLeft} />}
      </InputContainer>
    </InputRow>
  );
};

export default Input;
